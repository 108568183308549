import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createSubline(
        { commit, dispatch },
        {
            description,
            images,
            keywords,
            lineSlug,
            name,
            shortDescription,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/sublines", {
                name,
                description,
                shortDescription,
                slug,
                keywords,
                images,
                lineSlug
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async listSublinesPaginated(
        { commit, dispatch },
        { active, complete, keyword, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/sublines", {
                params: { active, complete, keyword, page }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async listSublinesByLine({ commit, dispatch }, { active, lineSlug }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/sublines/line/${lineSlug}`,
                { params: { active } }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async getSublineBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/sublines/${slug}`);

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async updateSubline(
        { commit, dispatch },
        {
            active,
            description,
            highlightType,
            images,
            keywords,
            lineSlug,
            name,
            shortDescription,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/sublines", {
                active,
                description,
                highlightType,
                images,
                keywords,
                lineSlug,
                name,
                shortDescription,
                slug
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async updateSublineCategories(
        { commit, dispatch },
        { categoriesSlugs, lineSlug }
    ) {
        try {
            await Http.instance.put("/bo/sublines/categories", {
                lineSlug,
                categoriesSlugs
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async updateSublineBrands({ commit, dispatch }, { brandsSlugs, lineSlug }) {
        try {
            await Http.instance.patch("/bo/sublines/brands", {
                lineSlug,
                brandsSlugs
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async deleteSubline({ commit, dispatch }, { slug }) {
        try {
            await Http.instance.delete(`/bo/sublines/${slug}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    }
};

export default actions;
