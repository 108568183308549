import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createCategory(
        { commit, dispatch },
        { description, images, keywords, name, shortDescription, slug }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/categories", {
                name,
                description,
                shortDescription,
                slug,
                keywords,
                images
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async listAllCategories(
        { commit, dispatch, state },
        { active, keyword = "", required }
    ) {
        if (!required) {
            const lower = keyword.toLowerCase();
            return state.categoriesAll.filter((item) => {
                const auxObj = {
                    ...{
                        ...(keyword
                            ? {
                                  keyword: item.name
                                      .toLowerCase()
                                      .includes(lower)
                              }
                            : null)
                    },
                    ...{
                        ...(active !== undefined
                            ? {
                                  active: item.active === active
                              }
                            : null)
                    }
                };
                return Object.values(auxObj).every((item2) => item2);
            });
        }
        try {
            const { data } = await Http.instance.get("/bo/categories/all", {
                params: { active, keyword }
            });
            commit("ADD_ALL_CATEGORIES", data.items);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async listCategoriesPaginated(
        { commit, dispatch },
        { active, complete, keyword, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/categories", {
                params: { active, complete, keyword, page }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async getCategoryBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/categories/${slug}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async updateCategory(
        { commit, dispatch },
        { active, description, images, keywords, name, shortDescription, slug }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/categories", {
                name,
                description,
                shortDescription,
                keywords,
                slug,
                active,
                images
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async deleteCategory({ commit, dispatch }, { slug }) {
        try {
            await Http.instance.delete(`/bo/categories/${slug}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async listHighlightCategories({ commit, dispatch }, { lineSlug }) {
        try {
            const { data } = await Http.instance.get(
                "/bo/categories/highlight",
                {
                    params: { lineSlug }
                }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    },

    async updateHighlightCategories(
        { commit, dispatch },
        { categoriesSlugs, lineSlug }
    ) {
        try {
            const { data } = await Http.instance.put(
                "/bo/categories/highlight",
                {
                    lineSlug,
                    categoriesSlugs
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CATEGORIES", parseError);
            throw parseError;
        }
    }
};

export default actions;
