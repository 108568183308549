import { DateTime } from "luxon";
import YAML from "yaml";

import { MARKETPLACE_URL, TIMEZONE } from "@/common/constants";
import { deliveryOptions } from "@/common/fields/delivery-options";
import { deliveryStatusesCodes } from "@/common/fields/delivery-status-codes";
import { paymentMethodsAbbreviationReader } from "@/common/fields/payment-methods";
import { paymentStatusesCodes } from "@/common/fields/payment-status-codes";
import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";
import { formatReference } from "@/common/lib/reference";
import { applyFormatOrder } from "@/common/lib/transformers";

const _getDate = (date) =>
    DateTime.fromJSDate(new Date(date), {
        zone: TIMEZONE
    }).toFormat("yyyy-MM-dd hh:mm");

const _formatDate = (managementDates) => {
    return managementDates
        ? managementDates.map((item) => _getDate(item.value))
        : [];
};

const actions = {
    async listOrdersByCustomerAndPage(
        { commit, dispatch },
        { customerId, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/orders", {
                params: { page, customerId, deliveryStatus: "cmp" }
            });
            const items = data.items.map(applyFormatOrder);
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listOrdersOwnPaginated(
        { commit, dispatch },
        { complete, merchantId, merchantType, page, query }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/orders/own", {
                params: { ...query, page, complete, merchantId, merchantType }
            });
            const items = data.items.map(applyFormatOrder);
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listOrdersPaginated({ commit, dispatch }, { complete, page, query }) {
        try {
            const { data } = await Http.instance.get("/bo/orders", {
                params: { ...query, page, complete }
            });
            const items = data.items.map(applyFormatOrder);
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listOrdersSchedule(
        { commit, dispatch },
        {
            begin,
            customerId,
            deliveryOption,
            deliveryStatus,
            end,
            forwardDays,
            forwardMonths,
            merchantId,
            merchantType,
            origin,
            paymentStatus,
            sellerId,
            uid
        }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/orders/schedule", {
                params: {
                    deliveryStatus,
                    deliveryOption,
                    paymentStatus,
                    customerId,
                    sellerId,
                    merchantType,
                    merchantId,
                    uid,
                    origin,
                    begin,
                    end,
                    forwardDays,
                    forwardMonths
                }
            });
            const items = data.items.map(applyFormatOrder);
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listAllOrders({ commit, dispatch }) {
        try {
            const { data } = await Http.instance.get("/bo/orders/all");
            const orders = data.items.map((item) => ({
                id: item.id,
                createdAt: _getDate(item.createdAt),
                updatedAt: _getDate(item.updatedAt),
                total: item.total,
                deliveryStatusMessage:
                    deliveryStatusesCodes[item.deliveryStatus] ||
                    deliveryStatusesCodes.pnd,
                paymentStatusMessage:
                    paymentStatusesCodes[item.paymentStatus] ||
                    paymentStatusesCodes.pnd,
                deliveryOptionMessage:
                    deliveryOptions[item.deliveryOption] ||
                    deliveryOptions.home,
                paymentMethodMessage:
                    paymentMethodsAbbreviationReader[item.paymentMethod] ||
                    paymentMethodsAbbreviationReader.cashondelivery,
                references: YAML.stringify(
                    item.referencesSnapshot.map((item2, index) => ({
                        id: item2.id,
                        barcode: item2.barcode,
                        name: item.references[index].product.name,
                        quantity: item2.quantity,
                        price: item2.priceOffer
                    }))
                ),
                location: YAML.stringify(item.location)
            }));
            return orders;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listNumberOrdersByReferences({ commit, dispatch }, deliveryStatus) {
        try {
            const { data } = await Http.instance.get("/bo/orders/references", {
                params: {
                    deliveryStatus
                }
            });
            const references = data.map((item) => ({
                ...item,
                productId: item.productId,
                url: `${MARKETPLACE_URL}/${item.slug}?reference=${item.id}`,
                ordersLen: item.orders.length
            }));
            return references;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async consultancyOrder({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/orders/consultancy/${id}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async getOrderOwnById(
        { commit, dispatch },
        { id, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/orders/${merchantType}/${merchantId}/${id}`
            );

            const result = {
                ...data,
                ...{
                    ...(data.deliveryAt
                        ? { deliveryAt: data.deliveryAt }
                        : null)
                },
                references: data.references.map(formatReference),
                anonymousReferences: data.anonymousReferences
            };
            return result;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async getOrderById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/orders/${id}`);

            const result = {
                ...data,
                ...{
                    ...(data.deliveryAt
                        ? { deliveryAt: data.deliveryAt }
                        : null)
                },
                references: data.references.map(formatReference)
            };
            return result;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async updateOrder(
        { commit, dispatch, state },
        {
            anonymousReferences,
            customerLocationId,
            deliveryAt,
            deliveryOption,
            deliveryStatus,
            id,
            observation,
            paymentMethod,
            paymentStatus,
            references,
            stockDecremented,
            valuePaid
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/orders", {
                id,
                valuePaid,
                observation,
                deliveryStatus,
                deliveryOption,
                paymentStatus,
                paymentMethod,
                customerLocationId,
                deliveryAt,
                references,
                stockDecremented,
                anonymousReferences
            });

            let orders = [...state.orders];
            const index = orders.findIndex((item) => item.id === id);
            if (index) {
                orders[index] = {
                    ...orders[index],
                    ...{
                        ...(paymentStatus
                            ? {
                                  paymentStatus,
                                  paymentStatusMessage:
                                      paymentStatusesCodes[paymentStatus] ||
                                      paymentStatusesCodes.pnd
                              }
                            : null)
                    },
                    ...{
                        ...(deliveryStatus
                            ? {
                                  deliveryStatus,
                                  deliveryStatusMessage:
                                      deliveryStatusesCodes[deliveryStatus] ||
                                      deliveryStatusesCodes.pnd
                              }
                            : null)
                    },
                    observation
                };
                commit("SET_ORDERS", orders);
            }
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async assignOrder(
        { commit, dispatch },
        { id, merchantId, merchantType, validateStock }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/orders/assign", {
                id,
                merchantId,
                merchantType,
                validateStock
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async completeOrder({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.post("/bo/orders/complete", {
                id
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async deleteOrder({ commit, dispatch, state }, { id }) {
        try {
            await Http.instance.delete(`/bo/orders/${id}`);
            const orders = [...state.orders];
            const index = orders.findIndex((item) => item.id === id);
            if (index) {
                orders.splice(index, 1);
                commit("SET_ORDERS", orders);
            }
        } catch (errorAxios) {
            commit("SET_ERROR_ORDERS", parseError);
            const parseError = handleErrorAxios(errorAxios, dispatch);
            throw parseError;
        }
    },
    async refactorOrder({ commit, dispatch }, { id, orders }) {
        try {
            const { data } = await Http.instance.post("/bo/orders/refactor", {
                id,
                orders
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async createSale(
        { commit, dispatch },
        {
            anonymousReferences,
            comments,
            customerId,
            merchantId,
            merchantType,
            paymentMethod,
            references,
            sellerId,
            sourcePriceMerchant,
            valuePaid,
            verifyAndDecreaseInventory
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/sales", {
                merchantId,
                merchantType,
                valuePaid,
                comments,
                sellerId,
                customerId,
                references,
                paymentMethod,
                anonymousReferences,
                sourcePriceMerchant,
                verifyAndDecreaseInventory
            });
            commit("commons/UPDATE_MERCHANTS_GLOBAL", data.merchant, {
                root: true
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listSalesByProduct({ commit, dispatch }, { idSku, query }) {
        try {
            const { data } = await Http.instance.get("/bo/sales/product", {
                params: { idSku, ...query }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async listSalesPaginated({ commit, dispatch }, { page }) {
        try {
            const { data } = await Http.instance.get("/bo/orders", {
                params: {
                    page,
                    deliveryStatus: "cmp"
                }
            });
            const items = data.items.map((item) => ({
                ...item,
                deliveryStatusMessage:
                    deliveryStatusesCodes[item.deliveryStatus] ||
                    deliveryStatusesCodes.pnd,
                paymentStatusMessage:
                    paymentStatusesCodes[item.paymentStatus] ||
                    paymentStatusesCodes.pnd,
                deliveryOptionMessage:
                    deliveryOptions[item.deliveryOption] ||
                    deliveryOptions.home,
                paymentMethodMessage:
                    paymentMethodsAbbreviationReader[item.paymentMethod] ||
                    paymentMethodsAbbreviationReader.cashondelivery,
                managementDates: _formatDate(item.managementDates),
                page
            }));
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },

    async listOrdersGroupsPaginated({ commit, dispatch }, { query }) {
        try {
            const { data } = await Http.instance.get("/bo/orders-groups", {
                params: query
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    },
    async getOrderGroupById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/orders-groups/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ORDERS", parseError);
            throw parseError;
        }
    }
};

export default actions;
