import "@/assets/css/bootstrap.css";
import Ability from "@/plugins/ability";
import Map from "@/plugins/map";
import VueMarkdownEditor from "@kangc/v-md-editor";
import esES from "@kangc/v-md-editor/lib/lang/es-ES";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import { Amplify } from "aws-amplify";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "leaflet/dist/leaflet.css";
import "sweetalert2/dist/sweetalert2.min.css";
import VueImg from "v-img";
import Vue from "vue";
import { LMap } from "vue2-leaflet";
import { Plugin } from "vue-fragment";
import VueMeta from "vue-meta";
import sounds from "vue-sounds";
import VueSweetalert2 from "vue-sweetalert2";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";

import {
    AUTH_REDIRECT_SIGN_IN,
    AUTH_REDIRECT_SIGN_OUT,
    AWS_REGION,
    BUSINESS_NAME,
    COGNITO_URL,
    COGNITO_USERS_POOLS_ID,
    COGNITO_USERS_POOLS_WEB_CLIENT_ID,
    ENABLED_ECOMMERCE_OPTIONS,
    ENABLED_GOOGLE_AUTH,
    MARKETPLACE_PORTAL_TITLE,
    MARKETPLACE_URL,
    OPERATION_MODE,
    SITE_DANGER_COLOR,
    SITE_DARK_COLOR,
    SITE_PRIMARY_COLOR,
    SITE_SUCCESS_COLOR,
    SITE_TYPE,
    SITE_WARNING_COLOR,
    TIMEZONE
} from "@/common/constants";
import {
    addressFilter,
    cityFilter,
    currencyFilter,
    cutStringFilter,
    dateFilter,
    dateSimpleFilter,
    imagesLengthFilter,
    merchantInfoFilter,
    moneyFilter,
    percentFilter,
    transformStringFilter
} from "@/common/lib/filters";

import App from "./App.vue";
import cashSound from "./assets/sounds/cash.wav";
import errorSound from "./assets/sounds/error.mp3";
import successSound from "./assets/sounds/success.mp3";
import wb from "./register-service-worker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = true;

VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use("es-ES", esES);
Vue.use(VueMeta);
Vue.use(VueMarkdownEditor);
Vue.use(VueSweetalert2);
Vue.use(Vuesax, {
    theme: {
        colors: {
            primary: SITE_PRIMARY_COLOR,
            success: SITE_SUCCESS_COLOR,
            danger: SITE_DANGER_COLOR,
            warning: SITE_WARNING_COLOR,
            dark: SITE_DARK_COLOR
        }
    }
});
Vue.use(sounds, store, {
    sounds: [
        {
            name: "cash",
            url: cashSound
        },
        {
            name: "error",
            url: errorSound
        },
        {
            name: "success",
            url: successSound
        }
    ]
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Plugin);
Vue.use(VueImg);
Vue.use(Ability);
Vue.use(Map);
Vue.filter("money", moneyFilter);
Vue.filter("city", cityFilter);
Vue.filter("merchantInfo", merchantInfoFilter);
Vue.filter("transformString", transformStringFilter);
Vue.filter("currency", currencyFilter);
Vue.filter("percent", percentFilter);
Vue.filter("date", dateFilter);
Vue.filter("dateSimple", dateSimpleFilter);
Vue.filter("image", imagesLengthFilter);
Vue.filter("address", addressFilter);
Vue.filter("cut", cutStringFilter);
Vue.component("LMap", LMap);
Vue.prototype.$workbox = wb;
Vue.prototype.$enabledEcommerceOptions = ENABLED_ECOMMERCE_OPTIONS;
Vue.prototype.$businessName = BUSINESS_NAME;
Vue.prototype.$siteTitle = MARKETPLACE_PORTAL_TITLE;
Vue.prototype.$siteType = SITE_TYPE;
Vue.prototype.$timezone = TIMEZONE;
Vue.prototype.$marketplaceUrl = MARKETPLACE_URL;
Vue.prototype.$operationMode = OPERATION_MODE;
Vue.prototype.$isPhone =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    false;

if (ENABLED_GOOGLE_AUTH) {
    Amplify.configure({
        aws_cognito_region: AWS_REGION, // (required) - Region where Amazon Cognito project was created
        aws_user_pools_id: COGNITO_USERS_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
        aws_user_pools_web_client_id: COGNITO_USERS_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
        aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in
        oauth: {
            domain: COGNITO_URL,
            scope: ["email", "openid", "profile"],
            redirectSignIn: AUTH_REDIRECT_SIGN_IN,
            redirectSignOut: AUTH_REDIRECT_SIGN_OUT,
            responseType: "code"
        },
        federationTarget: "COGNITO_USER_POOLS"
    });
}

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
